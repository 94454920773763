import React from "react";
import LoginPage from "./login-page";
import JoinPage from "./join-page";
import ResetPage from "./reset-pass-page";
import Confirmation from "./UI/confirmation";
import ErrorPage from "./UI/error";
import Testimonials from "./testimonials";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import Agreement from "./agreement";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/join">
          <JoinPage />
        </Route>
        <Route path="/reset">
          <ResetPage />
        </Route>
        <Route path="/confirm">
          <Confirmation />
          <LoginPage />
        </Route>
        <Route path="/maintenance">
          <ErrorPage />
        </Route>
        <Route path="/testimonials">
          <Testimonials />
        </Route>
        <Route path="/terms">
          <Agreement />
        </Route>
        <Route path="/">
          <LoginPage />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
