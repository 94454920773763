import React, { useState } from "react";
import axios from "axios";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { restorePasswordAction } from "./config";
import classes from "./login.module.css";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { IoArrowForwardOutline } from "react-icons/io5";
import clsx from "clsx";

const validationSchema = Yup.object().shape({
  email: Yup.string().email("Enter valid email").required("Required")
});

export default function InputRowDetails() {
  const [isOpen, setIsOpen] = useState(false);
  const [serverState, setServerState] = useState();

  const handleServerResponse = (ok, msg) => {
    setServerState({ ok, msg });
  };
  const handleOnSubmit = (values, actions) => {
    axios({
      method: "POST",
      url: restorePasswordAction,
      data: values
    })
      .then((response) => {
        actions.setSubmitting(false);
        actions.resetForm();
        handleServerResponse(true, succesMsg);
      })
      .catch((error) => {
        actions.setSubmitting(false);
        handleServerResponse(false, errMsg);
      });
  };

  const succesMsg = (
    <div className="succesMsgEmail">An email has been sent.</div>
  );
  const errMsg = (
    <div className={classes.errMsgEmail}>Server not responding.</div>
  );

  function handleClickDetails() {
    setIsOpen(!isOpen);
  }

  return (
    <div className="login-wrap pt-4 p-2">
      <Formik
        initialValues={{ email: "" }}
        onSubmit={handleOnSubmit}
        validationSchema={validationSchema}
      >
        {({ isSubmitting }) => {
          if (!isOpen) return null;
          return (
            <Form
              id="fs-frm-det"
              noValidate
              className={clsx(classes.passAction, "col-md-12", "p-0")}
            >
              <Field
                id="email-d"
                type="input"
                name="email"
                placeholder="Enter Email:"
                className={classes["input-row-email"]}
              />

              <ErrorMessage
                name="email"
                placeholder="Enter Email:"
                render={(msg) => (
                  <div className={classes["errorMsg-details"]}>
                    <p>{msg}</p>
                  </div>
                )}
              />

              <button
                type="submit"
                className={classes["input-row-email-submit"]}
                disabled={isSubmitting}
              >
                <span>
                  <IoArrowForwardOutline />
                </span>
              </button>

              <div className={classes["success-ok"]}>
                {serverState && (
                  <div className={!serverState.ok ? succesMsg : null}>
                    {serverState.msg}
                  </div>
                )}
              </div>
            </Form>
          );
        }}
      </Formik>

      <button
        onClick={handleClickDetails}
        className={classes["input-row-details"]}
        style={{
          transition: ".4s"
        }}
      >
        {!isOpen && (
          <>
            <span className="pr-2">
              <AiOutlineInfoCircle />
            </span>
            Forgot your details?
          </>
        )}
      </button>
    </div>
  );
}
