export const definitions = [
  {
    title: "TERMS AND CONDITIONS MEGAFFILIATE",
    section:
      "This is an agreement (“The Affiliate Agreement”) between you (“you” or “Affiliate”) and MegAffiliate, a affiliate program operated by Carambola company incorporated under the laws of: Prague with company number: 2903108, having its registered office at: Kaprova 42/14, Stare Mesto, 11000 Prague (“Company”, “us” or “we”). By registering for the Affiliate Program, and by accessing and using any of our marketing tools or accepting any reward, bonus or commission, whether contained in the Affiliate Agreement or elsewhere as a part of our Affiliate Program, you will be deemed to have read, understood and agreed to the Affiliate Agreement. We may periodically make modifications to this Agreement. While we will do our best to notify you of such changes, we recommend that you revisit this page regularly. Your continued use of the Affiliate Program will constitute your consent to the updated Agreement. This agreement is drafted in the English language. If this agreement is translated into another language, the English language text shall in any event prevail.",
    id: "agr101"
  },
  {
    title: "DEFINITIONS AND INTERPRETATION",
    section: [
      {
        subsection:
          '"Agreement" in this case means understanding and acceptance of the Terms and Conditions listed below.',
        id: "sec101"
      },
      {
        subsection:
          '"Affiliate" means the person that becomes part of this Affiliate Program.',
        id: "sec102"
      },
      {
        subsection:
          '"MegAffiliates Site" means our Megaffiliate website brand (Playzax.com)',
        id: "sec103"
      },
      {
        subsection:
          '"Affiliate Program" means the collaboration between the Company and the Affiliate, where the Affiliate will promote the Casino Playzax website and in this way, generate new casino players. The affiliate will receive a net revenue commission, defined under this agreement, based on the number of new players that deposit on the MegAffiliates site websites.',
        id: "sec104"
      },

      {
        subsection:
          '"The company" means Carambola company incorporated under the laws of: Prague with company number: CZ29031087, having its registered office at: Kaprova 42/14, Stare Mesto, 11000 Prague.',
        id: "sec105"
      },
      {
        subsection:
          '"Tracking link(s)" mean tracker IDs and feeds (RSS), made available by us in the affiliate area and that you may use to connect players to our services from your website (or other electronic method) or using other marketing materials for example promotional codes. When the relevant player opens his or her player account or keys in the applicable promotional code, our system automatically logs the tracking URL and records you as the referring affiliate.',
        id: "sec107"
      },
      {
        subsection:
          '"Commission" means the percentage of the Net Gaming Revenue, or, where applicable, a fixed amount for a New Customer (CPA structure) as set out in the Commission Structures.',
        id: "sec108"
      },
      {
        subsection:
          '"Commission Structures" means any specific reward structures expressly agreed between Company and the Affiliate.',
        id: "sec109"
      },
      {
        subsection:
          '"Net Revenue" means: the amount of real money deposits minus (a) the money paid to Customers as winnings, (b) bonuses and/or loyalty bonuses, (c) administration fees, (d) fraud costs, (e) charge-backs, (f) returned stakes (g) duties or taxes (h) jackpot contribution and (i) any commissions/fees due to third parties for providing/licensing games and/or games software. Moreover, in order to avoid misunderstandings, all the above-mentioned amounts are only the one generated from new customers referred to Casino Playzax by the Affiliate Website(s).',
        id: "sec110"
      },
      {
        subsection:
          '"Fraud traffic" means transactions, deposits, withdrawals, revenues or traffic generated on the services through illegal means or any other action committed in bad faith to defraud us (as determined by us in our sole discretion), regardless of whether or not it actually causes us harm, including transactions involving stolen credit/debit cards, collusion, manipulation of the service or system, bonuses or other promotional abuse, creation of false accounts for the purpose of generating affiliate earnings, and unauthorized use of any third-party accounts, copyrights, trademarks and other third party intellectual property rights (which, for the avoidance of doubt, includes our intellectual property rights).',
        id: "sec111"
      }
    ],
    id: "agr102"
  },
  {
    title: "SIGNING UP AND REGISTRATION",
    section: [
      {
        subsection:
          "In order to register with MegAffiliates you must be 18 years of age or older. The company reserves the right, at its own discretion, to conduct checks concerning your details to ensure they are correct, and shall be entitled to demand further evidence as to your age at any time in the future and to suspend and/or terminate your participation in a bet in the event that the company has grounds to believe that you are a minor.",
        id: "sec111"
      },
      {
        subsection:
          "Your username and password must be kept confidential at all times. Should you inadvertently let someone else know your login details you shall contact MegAffiliates immediately. You are responsible for any unauthorized use of your account.",
        id: "sec112"
      },
      {
        subsection:
          "You may only register one account. There is a limit of 1 account per affiliate. The company reserves the right to investigate and close one or more accounts if multiple accounts have been opened in order to create fraud. If there has been any activity in the new account before closure, then the account balance will be transferred to only one account minus the funds that were gained from any possible fraudulent activity.",
        id: "sec113"
      }
    ],
    id: "agr103"
  },

  {
    title: "COMPANY OBLIGATION",
    section: [
      {
        subsection:
          "The Company shall provide the Affiliate with all required information and marketing material for the implementation of the tracking link.",
        id: "sec114"
      },
      {
        subsection:
          "We will register your customers and will track their play. We reserve the right to refuse customers (or to close their accounts) if necessary, to comply with any requirements we may periodically establish.",
        id: "sec115"
      },
      {
        subsection:
          "The Company shall administrate the turnover generated via the tracking links, record the revenue and the total amount of commission earned via the link, provide the Affiliate with commission statistics, and handle all customer services related to the business. A unique tracking identification code/s will be assigned to all referred customers.",
        id: "sec116"
      },
      {
        subsection:
          "We shall use our best efforts to provide you with all materials and information required for necessary implementation of the Affiliate Links.",
        id: "sec117"
      }
    ],
    id: "agr105"
  },
  {
    title: "AFFILIATE RESPONSIBILITIES AND OBLIGATIONS",
    section: [
      {
        subsection: "The Affiliate hereby warrants:",
        id: "sec118"
      },
      {
        subsection:
          "To use its best efforts to actively and effectively advertise, market and promote our brand as widely as possible in order to maximize the benefit to the parties and to abide by the guidelines of the Company as they may be brought forward from time to time and/or as being published online.",
        id: "sec119"
      },
      {
        subsection:
          "To market and refer potential players to our brand at its own cost and expense. The Affiliate will be solely responsible for the distribution, content and manners of its marketing activities. All of the Affiliate's marketing activities must be professional, proper and lawful under applicable laws and must be in accordance with this Agreement.",
        id: "sec120"
      },
      {
        subsection:
          "To use only a tracking link provided within the scope of the affiliate program, otherwise no warranty whatsoever can be assumed for proper registration and sales accounting. Also, not to change or modify in any way any link or marketing material without prior written authorization from the Company.",
        id: "sec121"
      },
      {
        subsection:
          "To be responsible for the development, the operation, and the maintenance of its website as well as for all material appearing on its website.",
        id: "sec122"
      },
      {
        subsection:
          "The Affiliate will not perform any act which is libelous, discriminatory, obscene, unlawful or otherwise unsuitable or which contains sexually explicit, pornographic, obscene or graphically violent materials.",
        id: "sec123"
      },
      {
        subsection:
          "The Affiliate will not actively target any person who is under the legal age for gambling.",
        id: "sec124"
      },
      {
        subsection:
          "The Affiliate will not actively target any jurisdiction where gambling and the promotion thereof is illegal.",
        id: "sec125"
      },
      {
        subsection:
          "The Affiliate will not generate traffic by illegal or fraudulent activity, particularly but not limited to by: Sending spam and incorrect metatags.",
        id: "sec126"
      }
    ],
    id: "agr106"
  },
  {
    title: "COPYRIGHTS AND TRADE MARKS",
    section: [
      {
        subsection:
          'The Company holds the copyright to the text, logos and graphics provided on MegAffiliates program and the company Sites (hereinafter "Materials"). You undertake to respect all applicable copyrights and/or trademarks related to the company Sites, and to exercise all necessary prudence in the use of the websites and security measures. You are not permitted to alter, modify or change the Materials in any way whatsoever. You may not use any Materials for any purpose whatsoever other than promoting the Sites, and even then you may not do so without first submitting a sample of such use to us and receiving our prior written consent. You are not permitted to use the Materials in any manner that is disparaging or that otherwise portrays the company or anyone else negatively.',
        id: "sec101"
      },
      {
        subsection:
          "The company is not responsible for the accuracy of the content of any pages or advertisements, or any violation of any applicable laws, orders, rulings or regulations arising anywhere in the world from where the advertisements or pages can be accessed."
      }
    ],
    id: "agr107"
  },
  {
    title: "TERMINATION:",
    section: [
      {
        subsection:
          "This Agreement may be terminated by either party by giving a thirty (30) day written notification to the other party. Written notification may be given by an email.",
        id: "sub1"
      },
      {
        subsection:
          "The contracting parties hereby agree that upon the termination of this Agreement:",
        id: "sub2"
      },
      {
        subsection: [
          {
            sublist:
              "The Affiliate must remove all references to our site from the Affiliate's websites and/or other marketing channel and communications, irrespectively of whether the communications are commercial or non-commercial.",
            id: "sub3"
          },
          {
            sublist:
              "All rights and licenses granted to the Affiliate under this Agreement shall immediately terminate and all rights shall revert to the respective licensors, and the Affiliate will cease the use of any trademarks, service marks, logos and other designations vested in the Company.",
            id: "sub4"
          },
          {
            sublist:
              "The Affiliate will be entitled only to those earned and unpaid commissions as of the effective date of termination; however provided, the Company may withhold the Affiliate's final payment for a reasonable time to ensure that the correct amount is paid. The Affiliate will not be eligible to earn or receive commissions after this termination date.",
            id: "sub5"
          },
          {
            sublist:
              "The Affiliate will release the Company from all obligations and liabilities occurring or arising after the date of such termination, except with respect to those obligations that by their nature are designed to survive termination. Termination will not relieve the Affiliate from any liability arising from any breach of this Agreement, which occurred prior to termination and/or to any liability arising from any breach of confidential information even if the breach arises at any time following the termination of this Agreement.",
            id: "sub6"
          },
          {
            sublist:
              'Automatic Termination By Us If Your Account Is Inactive. If your Affiliate Account is inactive, your Agreement and participation in the Affiliate Network will automatically terminate. In this Section, "Inactive" means where you have not registered new Real Money Players for one hundred and eighty (180) days or more;. Where automatic termination occurs, we will notify you that your Fees will be frozen. If we do not receive any response from you within one hundred and eighty (180) days, any funds remaining within your Affiliate Account will revert to us.',
            id: "sub5"
          }
        ]
      }
    ],
    id: "agr108"
  }
];
