import clsx from "clsx";
import React from "react";
import maintenance from "../img/maintenance.svg";
import classes from "./alert-msg.module.css";

export default () => {
  return (
    <div
      className={clsx(
        classes["error-page"],
        "container",
        "d-flex",
        "flex-column",
        "justify-content-center",
        "align-items-center"
      )}
    >
      <h1>Maintenance</h1>
      <p>
        Sorry, this page is not available at this moment. Please try again
        later.
      </p>
      <img
        src={maintenance}
        style={{
          position: "absolute",
          zIndex: -1,
          width: 250,
          paddingBottom: 160
        }}
        alt=""
      />
    </div>
  );
};
