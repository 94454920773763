import React, { useState } from 'react'
import Captcha from './captcha'
import {captchaUrl} from '../config'

 const useCaptcha = (props) => {

  const [data, setData] = useState(captchaUrl + '&'+ Math.random())

  const refreshCaptcha = () => {
    setData(captchaUrl + '&'+ Math.random());
  }

  const captcha = data && <Captcha data={data}/>

  return [ captcha, refreshCaptcha ]
}

export default useCaptcha
