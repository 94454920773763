import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import classes from "./login.module.css";

const validationSchemaLogin = Yup.object().shape({
  name: Yup.string().required("Enter an email"),
  password: Yup.string().required("Enter a password")
});

export default (props) => {
  return (
    <>
      <Formik
        initialValues={{ name: "", password: "" }}
        onSubmit={(values, actions) => {
          props.handleOnSubmit(values, actions);
        }}
        validationSchema={validationSchemaLogin}
      >
        {({ isSubmitting }) => (
          <Form id="fs-frm-log" noValidate className="col-md-12, p-0">
            <div className="col-md-12 d-flex flex-column p-0">
              <div className="col-md-12 p-2 test">
                <Field
                  id="email-log"
                  type="text"
                  name="name"
                  className={classes.inputrowlog}
                  placeholder="Username"
                />
                <ErrorMessage
                  name="name"
                  render={(msg) => (
                    <div className={classes.errorMsg}>
                      <p>{msg}</p>
                    </div>
                  )}
                />
              </div>

              <div className="col-md-12 p-2 fs-frm-log">
                <Field
                  id="pass"
                  type="password"
                  name="password"
                  className={classes.inputrowlog}
                  placeholder="Password"
                />
                <ErrorMessage
                  name="password"
                  render={(msg) => (
                    <div className={classes.errorMsg}>
                      <p>{msg}</p>
                    </div>
                  )}
                />
              </div>
            </div>

            <div className="col-md-10 col-xl-7 p-2 d-flex flex-row align-items-center">
              <button
                type="submit"
                className={classes["input-row-login"]}
                disabled={isSubmitting}
              >
                SIGN IN
              </button>
              <div className="col-md-2 col-xl-5 p-2">
                <div className="spinner">{props.spinner}</div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};
