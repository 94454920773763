import React, { useState } from "react";
import classes from "./alert-msg.module.css";

function ConfirmSuccess() {
  const [isOpen, setIsOpen] = useState(true);
  return (
    <div
      className={
        isOpen ? classes["success-confirm"] : classes["success-confirm-out"]
      }
    >
      <p>Success!</p>
      <button
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        OK
      </button>
    </div>
  );
}

export default ConfirmSuccess;
