
import * as Yup from "yup"

export const validationSchema = Yup.object().shape({
  name: Yup.string()
    .max(50, 'Must be shorter than 50')
    .required('Enter a name'),

  companyName: Yup.string()
    .max(50, 'Must be shorter than 50')
    .required('Enter a company name'),

  email: Yup.string()
    .max(50, 'Must be shorter than 50')
    .email('Enter a valid email address')
    .required('Enter an email address'),

  lastName: Yup.string()
    .max(50, 'Must be shorter than 50')
    .required('Enter last name'),

  skype: Yup.string()
    .max(50, 'Must be shorter than 50'),

  phone: Yup.string()
    .max(50, 'Must be shorter than 50')
    .required('A phone number is requireds'),

  mobile: Yup.string()
    .max(50, 'Must be shorter than 50'),

  website: Yup.string()
      .max(50, 'Must be shorter than 50')
      .required('Enter URL'),

  password: Yup.string()
      .min(6, 'Enter at least 6 characters')
      .required("Password field is necessary"),

  confirmPassword: Yup.string()
      .test('passwords-match', 'Passwords don`t match', function(value) {
      return this.parent.password === value;
      })
      .required("Confirm password field"),

  agreeToTerms: Yup.bool()
    .required('Required')
    .oneOf([true], 'Accept Terms & Conditions is required'),

  captchaURL: Yup.string()
  .required('Type text from image'),

})
