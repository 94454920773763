import clsx from "clsx";
import React from "react";
import classes from "./alert-msg.module.css";

export default (props) => {
  return (
    <div className={classes["warning-screen"]}>
      <div className={classes["warning-bg"]}></div>
      <div
        className={clsx(
          classes["warning-bg-add"],
          "d-flex",
          "flex-column",
          "align-items-center",
          "justify-content-start",
          "col-md-5",
          "col-sm-8",
          "col-10"
        )}
      >
        <div
          className={clsx(
            classes.warning,
            "d-flex",
            "flex-column",
            "align-items-center",
            "justify-content-center"
          )}
        >
          <p>We are not able to create an account for you right now. </p>
          <button
            className={classes["button-warning"]}
            onClick={props.handleClickClose}
          >
            OK
          </button>
        </div>
      </div>
    </div>
  );
};
