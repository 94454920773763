import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import classes from "./login.module.css";

const validationSchemaLogin = Yup.object().shape({
  password: Yup.string().required("Password field is necessary"),
  confirmPassword: Yup.string()
    .test("passwords-match", "Passwords don`t match", function (value) {
      return this.parent.password === value;
    })
    .required("Confirm password field is necessary")
});

export default (props) => {
  return (
    <Formik
      initialValues={{ password: "", confirmPassword: "" }}
      onSubmit={(values, actions) => {
        props.handleOnSubmit(values, actions);
      }}
      validationSchema={validationSchemaLogin}
    >
      {({ isSubmitting }) => (
        <Form
          id="fs-frm-log"
          noValidate
          className={clsx("col-md-12", "p-0", classes.resetPassForm)}
        >
          <div className="col-md-12 d-flex flex-column p-0">
            <div className="col-md-12 p-2">
              <Field
                id="pass"
                type="password"
                name="password"
                className={classes["input-row-reset"]}
                placeholder="New Password"
              />
              <ErrorMessage
                name="password"
                render={(msg) => (
                  <div className={classes.errorMsg}>
                    <p>{msg}</p>
                  </div>
                )}
              />
            </div>

            <div className="col-md-12 p-2">
              <Field
                id="pass"
                type="password"
                name="confirmPassword"
                className={classes["input-row-reset"]}
                placeholder="Confirm New Password"
              />
              <ErrorMessage
                name="confirmPassword"
                render={(msg) => (
                  <div className={classes.errorMsg}>
                    <p>{msg}</p>
                  </div>
                )}
              />
            </div>
          </div>

          <div className="col-md-12 p-2 d-flex flex-row align-items-center">
            <button
              type="submit"
              className={classes["input-row-login"]}
              disabled={isSubmitting}
            >
              RESET
            </button>
            <div className="col-md-2 col-xl-5 p-2">
              <div className={classes.spinner}>{props.spinner}</div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
