import React from "react";
import axios from "axios";
import { loadTestimonials } from "./config/index";
import classes from "./testimonials.module.css";
import clsx from "clsx";
import { IoArrowBackOutline } from "react-icons/io5";
import { Link } from "react-router-dom";

export default function Testimonials() {
  const [data, setData] = React.useState([]);
  const [error, setError] = React.useState();
  const [isLoading, setLoading] = React.useState(false);

  React.useEffect(() => {
    axios({
      method: "GET",
      url: loadTestimonials
    })
      .then((response) => {
        setLoading(false);
        setData(response.data);
      })
      .catch((error) => {
        setLoading(false);
        setError(error);
      });
  }, []);

  const items = ["", "", "", ""];
  return (
    <section>
      <div className="container-fluid, bg-info">
        <header className={clsx(classes.titleBlock)}>
          <h2 className={classes.title}>TESTIMONIALS</h2>
          <hr className={classes.hr}></hr>
          <Link to="/">
            <div
              className={clsx(
                classes.linkBack,
                "d-flex",
                "align-items-center",
                "justify-content-center"
              )}
            >
              <IoArrowBackOutline />
              <span>SIGN IN</span>
            </div>
          </Link>
        </header>
      </div>
      {error && (
        <div className={classes.bgError}>
          <p className={classes.error}>{error && error.message}</p>
        </div>
      )}

      <div className={classes["testimony-wrap"]}>
        <div className="col-md-12 p-0 d-flex flex-column flex-md-row md-flex justify-content-center">
          <div className="col-md-6 col-12 p-0">
            {isLoading ? (
              <ul>
                {items.map((item, index) => (
                  <li
                    key={index}
                    className={clsx(
                      "mt-3",
                      "mb-3",
                      "d-flex",
                      "flex-column",
                      "align-items-center",
                      classes["testimonials-card"]
                    )}
                  >
                    <div
                      className="d-flex justify-content-center mb-4 p-0"
                      style={{
                        width: "50%",
                        height: 20,
                        background: "#f7f7f7",
                        borderRadius: 20
                      }}
                    ></div>
                    <p
                      style={{
                        width: "100%",
                        height: 90,
                        background: "#f7f7f7",
                        borderRadius: 10
                      }}
                    ></p>
                    <p
                      style={{
                        width: "80%",
                        height: 20,
                        background: "#f7f7f7",
                        borderRadius: 20
                      }}
                    ></p>
                  </li>
                ))}
              </ul>
            ) : (
              <ul>
                {data.map(
                  ({ id, src, title, description, href, background }, index) =>
                    index % 2 === 0 ? (
                      <li
                        key={index}
                        className={clsx(
                          "mt-3",
                          "mb-3",
                          "d-flex",
                          "flex-column",
                          "align-items-center",
                          classes["testimonials-card"]
                        )}
                      >
                        <div
                          style={{ background }}
                          className="d-flex justify-content-center mb-4 p-0 "
                        >
                          <img
                            key={id}
                            src={src}
                            alt={description}
                            style={{
                              maxWidth: "100%",
                              maxHeight: "80px",
                              margin: 8
                            }}
                          />
                        </div>
                        <p>"{title}"</p>
                        <a href={href}>{description}</a>
                      </li>
                    ) : null
                )}
              </ul>
            )}
          </div>

          <div className="col-md-6 col-sm-12 col-12 p-0">
            {isLoading ? (
              <ul>
                {items.map((item, index) => (
                  <li
                    key={index}
                    className={clsx(
                      "mt-3",
                      "mb-3",
                      "d-flex",
                      "flex-column",
                      "align-items-center",
                      classes["testimonials-card"]
                    )}
                  >
                    <div
                      className="d-flex justify-content-center mb-4 p-0"
                      style={{
                        width: "50%",
                        height: 20,
                        background: "#f7f7f7",
                        borderRadius: 20
                      }}
                    ></div>
                    <p
                      style={{
                        width: "100%",
                        height: 90,
                        background: "#f7f7f7",
                        borderRadius: 10
                      }}
                    ></p>
                    <p
                      style={{
                        width: "80%",
                        height: 20,
                        background: "#f7f7f7",
                        borderRadius: 20
                      }}
                    ></p>
                  </li>
                ))}
              </ul>
            ) : (
              <ul>
                {data.map(
                  ({ id, src, title, description, href, background }, index) =>
                    index % 2 !== 0 ? (
                      <li
                        key={index}
                        className={clsx(
                          "mt-3",
                          "mb-3",
                          "d-flex",
                          "flex-column",
                          "align-items-center",
                          classes["testimonials-card"]
                        )}
                      >
                        <div
                          style={{ background }}
                          className="d-flex justify-content-center mb-4 p-0 "
                        >
                          <img
                            key={id}
                            src={src}
                            alt={description}
                            style={{
                              maxWidth: "100%",
                              maxHeight: "80px",
                              margin: 8
                            }}
                          />
                        </div>

                        <p>"{title}"</p>
                        <a href={href}>{description}</a>
                      </li>
                    ) : null
                )}
              </ul>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
