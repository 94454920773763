import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { validationSchema } from "./utility/validation";
import classes from "./join.module.css";
import clsx from "clsx";
import { AiOutlineUser } from "react-icons/ai";
import { FaHandsHelping } from "react-icons/fa";
import { BsPencilSquare } from "react-icons/bs";

export default (props) => {
  return (
    <Formik
      initialValues={{
        name: "",
        email: "",
        phone: "",
        lastName: "",
        skype: "",
        mobile: "",
        companyName: "",
        website: "",
        address: "",
        position: "",
        password: "",
        confirmPassword: "",
        agreeToTerms: false,
        captchaURL: ""
      }}
      onSubmit={props.handleOnSubmit}
      validationSchema={validationSchema}
    >
      {({ isSubmitting }) => (
        <Form
          id="fs-frm-join"
          noValidate
          className={clsx("col-md-12", "p-0", classes.joinUsForm)}
        >
          <div className={clsx(classes["personal-details"], "pb-4")}>
            <h4>
              <span>
                <AiOutlineUser />
              </span>
              Personal details
            </h4>
            <div className="col-md-12 d-flex flex-column flex-md-row p-0">
              <div className="col-lg-6 col-xl-6 col-md-6 col-12 p-0">
                <div className="col-md-12 pl-0 pr-0 pb-2 pl-sm-3 pr-sm-3">
                  <Field
                    id="name"
                    type="text"
                    name="name"
                    className={classes["input-row-join"]}
                    placeholder="First name"
                  />
                  <ErrorMessage
                    name="name"
                    render={(msg) => (
                      <div className={classes["errorMsg-join"]}>
                        <p>{msg}</p>
                      </div>
                    )}
                  />
                </div>

                <div className="col-md-12 pl-0 pr-0 pb-2 pl-sm-3 pr-sm-3">
                  <Field
                    id="join-email"
                    type="email"
                    name="email"
                    className={classes["input-row-join"]}
                    placeholder="Email"
                  />
                  <ErrorMessage
                    name="email"
                    render={(msg) => (
                      <div className={classes["errorMsg-join"]}>
                        <p>{msg}</p>
                      </div>
                    )}
                  />
                </div>

                <div className="col-md-12 pl-0 pr-0 pb-2 pl-sm-3 pr-sm-3">
                  <Field
                    id="join-phone"
                    type="phone"
                    name="phone"
                    className={classes["input-row-join"]}
                    placeholder="Phone"
                  />
                  <ErrorMessage
                    name="phone"
                    render={(msg) => (
                      <div className={classes["errorMsg-join"]}>
                        <p>{msg}</p>
                      </div>
                    )}
                  />
                </div>
              </div>
              <div className="col-lg-6 col-xl-6 col-md-6 col-12 p-0">
                <div className="col-md-12 pl-0 pr-0 pb-2 pl-sm-3 pr-sm-3">
                  <Field
                    id="join-s-name"
                    type="text"
                    name="lastName"
                    className={classes["input-row-join"]}
                    placeholder="Last name"
                  />
                  <ErrorMessage
                    name="lastName"
                    render={(msg) => (
                      <div className={classes["errorMsg-join"]}>
                        <p>{msg}</p>
                      </div>
                    )}
                  />
                </div>
                <div className="col-md-12 pl-0 pr-0 pb-2 pl-sm-3 pr-sm-3">
                  <Field
                    id="join-skype"
                    type="text"
                    name="skype"
                    className={classes["input-row-join"]}
                    placeholder="Skype"
                  />
                  <ErrorMessage
                    name="skype"
                    render={(msg) => (
                      <div className={classes["errorMsg-join"]}>
                        <p>{msg}</p>
                      </div>
                    )}
                  />
                </div>
                <div className="col-md-12 pl-0 pr-0 pb-2 pl-sm-3 pr-sm-3">
                  <Field
                    id="join-mobile"
                    type="phone"
                    name="mobile"
                    className={classes["input-row-join"]}
                    placeholder="Mobile"
                  />
                  <ErrorMessage
                    name="mobile"
                    render={(msg) => (
                      <div className={classes["errorMsg-join"]}>
                        <p>{msg}</p>
                      </div>
                    )}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className={clsx(classes["company-details"], "pb-4")}>
            <h4>
              <span>
                <FaHandsHelping />
              </span>
              COMPANY DETAILS
            </h4>
            <div className="col-md-12 d-flex flex-column flex-md-row p-0">
              <div className="col-lg-6 col-xl-6 col-md-6 col-12 p-0">
                <div className="col-md-12 pl-0 pr-0 pb-2 pl-sm-3 pr-sm-3">
                  <Field
                    id="cd-name"
                    type="text"
                    name="companyName"
                    className={classes["input-row-join"]}
                    placeholder="Company name"
                  />
                  <ErrorMessage
                    name="companyName"
                    render={(msg) => (
                      <div className={classes["errorMsg-join"]}>
                        <p>{msg}</p>
                      </div>
                    )}
                  />
                </div>

                <div className="col-md-12 pl-0 pr-0 pb-2 pl-sm-3 pr-sm-3">
                  <Field
                    id="cd-site"
                    type="text"
                    name="website"
                    className={classes["input-row-join"]}
                    placeholder="Website URL"
                  />
                  <ErrorMessage
                    name="website"
                    render={(msg) => (
                      <div className={classes["errorMsg-join"]}>
                        <p>{msg}</p>
                      </div>
                    )}
                  />
                </div>
              </div>
              <div className="col-lg-6 col-xl-6 col-md-6 col-12 p-0">
                <div className="col-md-12 pl-0 pr-0 pb-2 pl-sm-3 pr-sm-3">
                  <Field
                    id="cd-adres"
                    type="text"
                    name="address"
                    className={classes["input-row-join"]}
                    placeholder="Address"
                  />
                  <ErrorMessage
                    name="address"
                    render={(msg) => (
                      <div className={classes["errorMsg-join"]}>
                        <p>{msg}</p>
                      </div>
                    )}
                  />
                </div>
                <div className="col-md-12 pl-0 pr-0 pb-2 pl-sm-3 pr-sm-3">
                  <Field
                    id="cd-position"
                    type="text"
                    name="position"
                    className={classes["input-row-join"]}
                    placeholder="Position"
                  />
                  <ErrorMessage
                    name="position"
                    render={(msg) => (
                      <div className={classes["errorMsg-join"]}>
                        <p>{msg}</p>
                      </div>
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={clsx(classes["account-details"], "pb-4")}>
            <h4>
              <span>
                <BsPencilSquare />
              </span>
              ACCOUNT DETAILS
            </h4>
            <div className="col-md-12 d-flex flex-column flex-md-row p-0">
              <div className="col-lg-6 col-xl-6 col-md-6 col-12 p-0">
                <div className="col-md-12 pl-0 pr-0 pb-2 pl-sm-3 pr-sm-3">
                  <Field
                    id="cd-pass"
                    type="password"
                    name="password"
                    className={classes["input-row-join"]}
                    placeholder="Password"
                  />
                  <ErrorMessage
                    name="password"
                    render={(msg) => (
                      <div className={classes["errorMsg-join"]}>
                        <p>{msg}</p>
                      </div>
                    )}
                  />
                </div>
              </div>
              <div className="col-lg-6 col-xl-6 col-md-6 col-12 p-0">
                <div className="col-md-12 pl-0 pr-0 pb-2 pl-sm-3 pr-sm-3">
                  <Field
                    id="cd-pass-con"
                    type="password"
                    name="confirmPassword"
                    className={classes["input-row-join"]}
                    placeholder="Confirm Password"
                  />
                  <ErrorMessage
                    name="confirmPassword"
                    render={(msg) => (
                      <div className={classes["errorMsg-join"]}>
                        <p>{msg}</p>
                      </div>
                    )}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-12 d-flex flex-row pl-0 pr-0">
              <div className="col-md-6 col-sm-6 col-8 pl-0 pl-sm-3 pr-sm-3">
                <Field
                  name="captchaURL"
                  type="text"
                  className={classes["input-row-join"]}
                  placeholder="Verify Code"
                />
                <ErrorMessage
                  name="captchaURL"
                  render={(msg) => (
                    <div className={classes["errorMsg-join"]}>
                      <p>{msg}</p>
                    </div>
                  )}
                />
              </div>

              <div className="col-md-6 col-sm-6 col-4 p-0 d-flex flex-column flex-sm-row align-items-center">
                {props.captcha}
              </div>
            </div>
            <div className="col-6 ml-auto">{props.warning}</div>

            <div
              className={clsx(
                classes["accept-section"],
                "col-md-12",
                "d-flex",
                "flex-column",
                "pt-4"
              )}
            >
              <div className="col-md-12 p-0 pl-sm-3 pr-sm-3 d-flex flex-row pb-2">
                <div className="col-1 d-flex align-items-center justify-content-center">
                  <Field name="agreeToTerms" type="checkbox" />
                </div>
                <div className="col-12 pl-1">
                  <label
                    htmlFor="acceptTerms"
                    className={classes["form-check-label"]}
                  >
                    Accept{" "}
                    <span className={classes["form-check-label_accept"]}>
                      <a href="/terms">Terms & Conditions</a>
                    </span>
                  </label>
                </div>
                <ErrorMessage
                  name="agreeToTerms"
                  render={(msg) => (
                    <div className={classes["errorMsg-join-agreement"]}>
                      <p>{msg}</p>
                    </div>
                  )}
                />
              </div>

              <div className="col-md-12 p-0 pl-sm-3 pr-sm-3 d-flex flex-row">
                <div className="col-1 d-flex align-items-center justify-content-center">
                  <Field name="agreeToNews" type="checkbox" />
                </div>
                <div className="col-11 pl-1">
                  <label
                    htmlFor="agreeToNews"
                    className={classes["form-check-label"]}
                  >
                    I would like to receive news and notifications
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex flex-row align-items-center col-md-12 mb-3">
            <button
              type="submit"
              className={clsx(classes["input-row-join-btn"], "col-md-4")}
            >
              RESIGTER NOW!
            </button>
            <div className="ml-3">{props.spinner}</div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
