import React, { useState } from "react";
import classes from "./alert-msg.module.css";

const useWarningCaptch = (props) => {
  const [visible, setVisible] = useState(false);
  const showWarningCaptch = () => setVisible(true);
  const hideWarningCaptch = () => setVisible(false);
  const warning = visible ? (
    <p className={classes["warn-captcha"]}>Invalid captcha code.</p>
  ) : null;

  return [warning, showWarningCaptch, hideWarningCaptch];
};
export default useWarningCaptch;
