import React from "react";
import { definitions } from "./data-agr";
import classes from "./agr.module.css";
import clsx from "clsx";
import { IoArrowBackOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import buzzLogo from "./img/buzz.svg";

const Agreement = () => {
  return (
    <>
      <div>
        <div className="container">
          <div
            className={clsx(
              classes.head,
              "d-flex",
              "pt-5",
              "align-items-center"
            )}
          >
            <img
              src={buzzLogo}
              width={250}
              className={classes.mlogo}
              alt="brand"
            />
          </div>
          <hr className={classes.hr}></hr>
          <Link to="/join">
            <div
              className={clsx(
                classes.linkBack,
                "d-flex",
                "align-items-center",
                "justify-content-center"
              )}
            >
              <IoArrowBackOutline />
              <span>Join now</span>
            </div>
          </Link>
        </div>
      </div>
      <div className="container">
        {definitions?.map((data) => {
          return (
            <section key={data.id}>
              <h4 className="pb-4 pt-4">{data.title}</h4>
              {Array.isArray(data.section) ? (
                data.section.map((level, index) => {
                  return Array.isArray(level.subsection) ? (
                    <ol>
                      {level.subsection.map((list) => {
                        return <li key={list.id}>{list.sublist}</li>;
                      })}
                    </ol>
                  ) : (
                    <p key={index}>{level.subsection}</p>
                  );
                })
              ) : (
                <p>{data.section}</p>
              )}
            </section>
          );
        })}
      </div>
    </>
  );
};
export default Agreement;
