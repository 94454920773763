import React, { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import classes from "./join.module.css";
import clsx from "clsx";
import WarningJoin from "./UI/warning-join";
import useWarningCaptch from "./UI/use-warn-captch";
import { joinAction } from "./config";
import JoinForm from "./join-form";
import useCaptcha from "./UI/use-refresh-captch";
import useSpinner from "./UI/use-spinner";
import { IoArrowBackOutline } from "react-icons/io5";

export default () => {
  const [warning, showWarningCaptch, hideWarningCaptch] = useWarningCaptch();
  const [serverState, setServerState] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [captcha, refreshCaptcha] = useCaptcha();
  const [spinner, showSpinner, hideSpinner] = useSpinner();

  const serverResponse = (isSuccess, message) => {
    setServerState({ isSuccess, message });
  };

  const handleOnSubmit = (values, actions) => {
    showSpinner();
    axios({
      method: "POST",
      url: joinAction,
      data: values
    })
      .then((response) => {
        hideSpinner();
        refreshCaptcha();
        actions.setSubmitting(false);
        actions.resetForm();
        serverResponse(true, setIsVisible(false));
      })
      .catch((error) => {
        if (error.response.data !== "") {
          refreshCaptcha();
          showWarningCaptch();
          hideSpinner();
          actions.setSubmitting(false);
        } else {
          actions.setSubmitting(false);
          setIsOpen(true);
          hideSpinner();
          hideWarningCaptch();
          serverResponse(
            false,
            <WarningJoin handleClickClose={() => setIsOpen(false)} />
          );
          actions.resetForm();
        }
      });
  };
  return (
    <div className={clsx(classes["content-join"], "d-flex", "flex-row")}>
      <div className="col-lg-10 col-xl-8 mb-4">
        <div className="col-md-12">
          <Link to="/">
            <div
              className={clsx(
                classes.linkMember,
                "d-flex",
                "align-items-center",
                "justify-content-center"
              )}
            >
              <IoArrowBackOutline />
              <span>SIGN IN</span>
            </div>
          </Link>
          <hr className={classes.hr}></hr>
          {isVisible ? (
            <>
              <p className={classes["join-info"]}>
                Enter your details below and you will get instant access to all
                marketing materials to get started.
              </p>
              <JoinForm
                handleOnSubmit={handleOnSubmit}
                warning={warning}
                captcha={captcha}
                spinner={spinner}
              />
            </>
          ) : (
            <p className={classes.succesMsg}>
              Thank You! We will contact you shortly…{" "}
            </p>
          )}
        </div>
      </div>
      <div className={clsx("col-md-5", classes["join-brand"])}></div>
      {isOpen && serverState && (
        <div className={classes.popup}>{serverState.message}</div>
      )}
    </div>
  );
};
